<template>
    <v-container fluid>
        <PageLayout>
            <TabsNav />
            <div class="d-flex justify-space-between mb-10">
                <div class="d-flex align-center">
                    <h1>
                        {{ filter_show ? $t('search_company') : $t('company') }}
                    </h1>
                    <Btn
                        :disabled="userBlock"
                        small
                        icon
                        class="mr-5"
                        color="primary"
                        @click="dialogImportFile=true" :title="$t('assign_new_client')"
                    >
                        <v-icon class="mx-2" small>mdi-file-image-plus-outline</v-icon>
                    </Btn>
                </div>
                <div class="text-right">
                    <Btn large :disabled="userBlock" :to="{name: 'company.create'}" color="secondary">
                        {{ $t('create') }}
                    </Btn>
                </div>
            </div>

            <v-row>
                <v-col>
                    <v-form @submit.prevent="getCompanies('search')">
                        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                            <v-row  v-if="filter_show">
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="company" rules="min:1|max:255" v-slot="{ errors, valid }">
                                        <v-text-field v-model="company" type="text" :error-messages="errors"
                                                      :label="$t('company_name')"
                                                      autocomplete="off"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="3">
                                    <ValidationProvider ref="bin_iin" rules="numeric|bin_iin"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="bin_iin" type="text" :error-messages="errors"
                                                      :label="$t('bin_iin')"
                                                      autocomplete="off"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="3">
                                    <ValidationProvider ref="contract_number" rules="min:3|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="contract_number" type="text" :error-messages="errors"
                                                      :label="$t('contract_number')"
                                                      autocomplete="off"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="phone" rules="phone"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="phoneRaw" type="tel"
                                                      v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                      :error-messages="errors"
                                                      :label="$t('phone')"
                                                      autocomplete="off"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="email" rules="email"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="email" type="email" :error-messages="errors"
                                                      :label="$t('email')"
                                                      autocomplete="off"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0" v-if="filter_show">
                                <v-col cols="12" sm="3" md="2">
                                    <v-select :value="perPage" :items="perPageItems"
                                              :label="$t('items_per_page')"
                                              @input="perPage = options.itemsPerPage = Number($event)" hide-details

                                              autocomplete="off"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :disabled="loading"
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable></v-select>
                                </v-col>
                                <v-col cols="12" sm="9" md="10"
                                       class="d-flex justify-center justify-sm-end align-self-center">
                                    <v-btn type="submit" :disabled="invalid || loading" class="infinity_button"
                                           :block="$vuetify.breakpoint.xsOnly" color="primary">
                                        {{ $t('search') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </ValidationObserver>
                    </v-form>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <ResizableTable
                        class="main-data-table elevation-0 uveds-table"
                        :rows="companies"
                        :columns="filteredHeaders.map((item) => {
                        return {
                            prop: item.value,
                            label: item.text,
                            width: 'auto'
                        }
                    })"
                    >
                        <template v-slot:name="{ item }">
                            <div :class="item.deleted ? 'red--text' : ''">
                                <v-hover v-slot="{ hover }">
                                    <div>
                                        <span  class="cursor-pointer font_weight_600" @click="showCompany(item)" >
                                            {{ item.name }}
                                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                        </span>
                                        <v-icon v-if="hover" @click="editCompany(item)" class="ml-2"  :title="$t('edit')" >
                                            mdi-pencil mdi-18px
                                        </v-icon>
                                    </div>
                                </v-hover>
                            </div>
                        </template>
                        <template v-slot:phone="{ item }">
                            <div>{{ formatPhoneNumber(item.phone) }}</div>
                        </template>
                        <template v-slot:active="{ item }">
                            <div>{{ item.active ? $t('yes') : $t('no')}}</div>
                        </template>
                        <template v-slot:action="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on"  @click="createDocCompany(item)" icon>
                                        <v-icon>mdi-file-word</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('create_docx')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on"  @click="editCompany(item)" icon>
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('edit')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" :disabled="item.deleted" @click="deleteCompany(item)" icon>
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                            </v-tooltip>
                        </template>
                    </ResizableTable>
                    <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
                </v-col>
            </v-row>

            <template v-slot:side>
                <v-icon class="mb-5" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
                <v-list class="filter_left">
                    <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{$t('system_filters')}}</v-list-item-title>
                        </template>

                        <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>

                <v-list class="filter_left">
                    <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{$t('additional')}}</v-list-item-title>
                        </template>
                        <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </template>
        </PageLayout>
    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mask} from 'vue-the-mask'
    import PageLayout from "@/components/Leentech/PageLayout.vue"
    import Btn from "@/components/Form/Btn.vue";
    import TabsNav from "@/components/Leentech/TabsNav.vue";

    export default {
        name: "Companies",
        directives: {
            mask,
        },
        components: {
            TabsNav,
            Btn,
            ValidationProvider,
            ValidationObserver,
            PageLayout
        },
        data() {
            return {
                fab: false,
                phoneRaw: null,
                company: null,
                email: null,
                bin_iin: null,
                contract_number: null,
                companies: [],
                companyItems: [],
                sortBy: "name",
                sortDir: true,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalCompanies: 0,
                options: {},
                loading: false,
                headers: [
                    {
                        text: this.$t('company'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('phone'),
                        align: "left",
                        sortable: true,
                        value: "phone"
                    },
                    {
                        text: this.$t('email'),
                        align: "left",
                        sortable: true,
                        value: "email"
                    },
                    {
                        text: this.$t('bin_iin'),
                        align: "left",
                        sortable: true,
                        value: "bin_iin"
                    },
                    {
                        text: this.$t('contract_number'),
                        align: "left",
                        sortable: true,
                        value: "contract_number"
                    },
                    {
                        text: this.$t('active'),
                        align: "left",
                        sortable: true,
                        value: "active",
                    },
                ],
                system_filters: [
                    {'title': 'Все контакты', 'count': '10'},
                    {'title': 'Мои клиенты', 'count': '0'},
                    {'title': 'Избранное', 'count': '7'},
                ],
                Additional: [
                    {'title': 'База Казахстан', 'count': '10'},
                    {'title': 'База Россия', 'count': '10'},
                    {'title': 'База Узбекистан', 'count': '10'},
                ],
                group1: true,
                group2: true,
                filter_show: false,
            };
        },
        computed: {
            ...mapGetters(['lang', 'userBlock', 'itemsPerPage', 'perPageItems', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getCompanies()
                },
                deep: false
            }
        },
        methods: {
            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table6' : 'rowMobiClass';
            },
            showCompany(item) {
                this.$router.push({
                    name: 'company.show',
                    params: {
                        id: item.uuid
                    }
                })
            },
            editCompany(item) {
                this.$router.push({
                    name: 'company.edit',
                    params: {
                        id: item.uuid
                    }
                })
            },
            async createDocCompany(item) {
                this.loading = true;
                let params = {};

                if (item.uuid) {
                    params.companu_uuid = item.uuid;
                }
                if (item.id) {
                    params.companu_id = item.id;
                }

                await this.$http
                    .get("admin/test_docx", {
                        params: params,
                    })
                    .then(res => {
                    //    this.companies = res.body.data

                    })
                    .catch(err => {

                        this.$toastr.error(this.$t('failed_to_get_list_companies'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },

            async deleteCompany(item) {
                if (confirm(this.$t('delete_company'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/company/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('company_has_been_deleted'))
                            this.getCompanies()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('company_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async getCompanies(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.company) {
                    params.company = this.company;
                }
                if (this.phone) {
                    params.phone = this.phone;
                }
                if (this.email) {
                    params.email = this.email;
                }
                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then(res => {
                        this.companies = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalCompanies = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.companies = []
                        this.totalCompanies = 0
                        this.$toastr.error(this.$t('failed_to_get_list_companies'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
</script>
